.moveUpDown {
  animation: MoveUpDown 4s linear infinite;
  position: absolute;
  /* left: 0;
  bottom: 0; */
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 110px;
  }
  50% {
    bottom: 90px;
  }
}

.heart {
  animation: heartbeat 3s infinite;
  user-select: none;
}

.heart img {
  /* width: 150px; */
}

/* Animation */
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
